.header_container {
    margin-top: 15px;
    margin-bottom: 20px;

    .main_container {
        display: grid;
        grid-template-columns: 60% 37%;

        .header_container_filters {
            display: grid;

            .dropdown_filter {
                margin-right: 10px;
                width: 110px;
            }

            .search_filter {
                margin-right: 10px;
                width: 201px;
            }

            .button_container {
                margin-right: 10px;
                display: flex;
                justify-content: flex-end;
            }

            .more_filters {
                background-color: #E8E8EC;
                color: #404767;
                width: 83px;
                font-size: 10px;
                height: 30px;
                margin-right: 5px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-weight: 500;
            }

            .filter_reset {
                color: #fc441e;
                font-weight: 500;
                font-size: 12px;
                line-height: 15.21px;
                margin: 5px;
                padding-left: 15px;
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }

        .header_container_filters {
            grid-template-columns: 220px 220px 80px 1fr;
        }

        .raise_ticket_container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.filter_drawer_container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .filter_drawer_content {
        padding: 10px;
    }
    .accordion_content {
        padding: 10px !important;
    }
    .filter_drawer_header {
        padding: 10px 20px;
        background-color: #fbfbfc;

        h6 {
            font-weight: 500;
            font-size: 15px;
        }
    }
    .filter_drawer_footer {
        flex: 1;
        display: flex;
        align-items: end;
        gap: 10px;
        padding: 10px;

        button {
            font-size: 15px;
        }

        .reset_button {
            background-color: rgba(249, 249, 250, 0.7);
            color: rgba(16, 25, 65, 1);
            border: none;
        }
    }
}

@media (max-width: 767px) {
    .header_container_filters {
        display: grid;
        grid-template-columns: repeat(2, 50%) !important;
        gap: 10px !important;

        .search_filter {
            width: 100% !important;
            grid-column: span 2;
        }

        .dropdown_filter {
            width: 100% !important;
        }
    }
}
