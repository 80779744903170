@media(min-width:768px) {
    .layoutV2_container {
        padding: 24px;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        max-height: 100vh;
        overflow: hidden;
        background: #E8E8EC;

        .layoutV2_container_header {
            height: 3%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .layoutV2_container_header_left {
                display: flex;
                align-items: center;

                .label_container {
                    width: 30%;
                    max-width: 50%;
                    margin-left: 2%;
                }

                .layoutV2_container_sidemenuButton {
                    width: 20px;
                    height: 18px;
                    margin: 0 5px 0 24px;
                }

                .heading_container {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .tooltip_container {
                        top: 3px;
                    }

                    .dealType_container {
                        border-left: 4px solid #FC5735;
                        color: #FC5735;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: #FFF;
                        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
                        // height: 20px;
                        display: flex;
                        align-items: center;
                        padding: 5px;
                    }

                    .layoutV2_container_heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px !important;
                        // line-height: 24px;
                        color: #101941;

                    }
                }

                .layoutV2_container_sub_heading {
                    // margin: 0px 0 0 24px;
                    width: 28px;
                    height: 2%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px !important;
                    // line-height: 30px;
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #70758D;
                }
            }

            .layoutV2_container_header_right {
                display: flex;
                align-items: center;

                .notification_cont {
                    position: absolute;
                    z-index: 9999999999999999;
                    right: 17px;
                    top: -23px;
                }

                .notification_icon {
                    height: 20px;
                    width: 20px;
                    margin-right: 15px;
                }

                .user_initial {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 24px;
                    font-size: 11.5px;
                    font-weight: 500;
                    color: #FFF;
                }
            }
        }

        .layoutV2_container_list_container {
            border-radius: 4px;
            height: 97%;
            margin-top: 12px;
            padding: 24px;
            background-color: #FFFF;
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        }
    }
}

@media(max-width:767px) {
    .layoutV2_container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        background: #FFFF;
        .label_container_mob{
            width: max-content;
            margin: 5px 5px 5px 24px;
        }

        .layoutV2_container_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px 0 0 0;

            .layoutV2_container_header_left {
                display: flex;
                align-items: center;

                .layoutV2_container_sidemenuButton {
                    width: 20px;
                    height: 18px;
                    margin: 0 5px 0 24px;
                }

                .layoutV2_container_heading {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px !important;
                    line-height: 24px;
                    color: #101941;
                }

                .layoutV2_container_sub_heading {
                    margin: 0px 0 0 24px;
                    width: 28px;
                    height: 2%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px !important;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #70758D;
                }
            }

            .layoutV2_container_header_right {
                display: flex;
                align-items: center;

                .notification_cont {
                    position: absolute;
                    z-index: 9999999999999999;
                    right: 17px;
                    top: -23px;
                }

                .notification_icon {
                    height: 20px;
                    width: 20px;
                    margin-right: 15px;
                }

                .user_initial {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 24px;
                    font-size: 11.5px;
                    font-weight: 500;
                    color: #FFF;
                }
            }
        }


        .layoutV2_container_list_container {
            border-radius: 4px;
            height: 97%;
            padding: 0 16px 16px 16px;
            background-color: #FFFF;
        }
    }
}

img {
    cursor: pointer;
}